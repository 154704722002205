<!-- 会员管理 -->
<template>
  <div>
    <div class="mainBody">
      <!--  左边组别-->
      <div class="left-part">
        <ul class="infinite-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="stop"
            infinite-scroll-immediate="true"
            infinite-scroll-distance="1"
            style="overflow:auto">
          <!--单个组-->
          <li v-for="(g,index) in groups" :key="g.id"
              class="per-group"
              :class="{'active':active===g.id, 'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}"
              :ref='g.id'
              @click="g.id === 0 ? null : chooseGroup(g)" >
            <el-tooltip :content="g.groupName" placement="top" effect="dark">
              <span class="ellipsis-tooltip">{{ g.groupName }}</span>
            </el-tooltip>
            <span v-if="!g.end">{{g.price+' '+g.priceType+'/'+$t('Year')}}</span>
      
          </li>
    
        </ul>
        <div v-infinite-scroll="loadMore" :infinite-scroll-disabled="isLoading">
          <div v-if="isLoading" class="loading-text">Loading...</div>
        </div>
      </div>
      
      <!--右边-->
      <div class="right-part">
        <!--搜索框-->
        <div class="filterBarPay">
          <div class="up">
            <!--公司名称-->
            <div>
              <label>{{ $t('CompanyName') }}：</label>
              <input v-model.trim="companyName">
            </div>
            <!--缴费人-->
            <div>
              <label>{{ $t("PayPerson") }}：</label>
              <input
                  type="text"
                  v-model.trim="realName"
              >
            </div>
            <el-button @click="searchUserData">{{ $t('Search') }}</el-button>
          </div>
          <div class="down">
            <span>{{$t('Annual')+$t('AllIncome') + feeTotal}}</span>
            <span>{{$t('PayPeople') + peopleTotal}}</span>
            <span>{{$t('Expire')+"："+expireDate}}</span>
            <el-tooltip class="item" effect="dark" :content="$t('ResetTime')+openDate" placement="top" :disabled="!open">
              <el-button @click="setMembershipFeeDialog = true" :disabled="open || myRight<3">{{ this.$t('SetMembershipFee')}}</el-button>
            </el-tooltip>
          </div>
  
        </div>
  
        <!--分组列表-->
        <div class="group-form">
    
          <!--  右边表格-->
          <el-table
              :data="membershipList"
              border
              class="right-form"
              style="width: 99%;margin: 0 auto"
          >
            <!--公司名-->
            <el-table-column
                prop="companyName"
                :label="$t('CompanyName')"
            >
            </el-table-column>
            <!--缴费人-->
            <el-table-column
                prop="realName"
                :label="$t('PayPerson')"
            >
            </el-table-column>
            <!--全部缴费金额-->
            <el-table-column
                prop="totalPrice"
                :label="$t('AllData')+$t('PayAmount')"
            >
            </el-table-column>
            <!--操作-->
            <el-table-column
                prop="operation"
                width="210"
                :label="$t('Operation')"
                center
            >
              <template slot-scope="scope">
                <el-button
                    type="text"
                    size="small"
                    @click="toDetail(scope.row)"
                >{{ $t("Detail") }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--分页-->
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="userTotal"
            v-if="membershipList.length!==0"
            style="margin: 10px auto 10px 20px;"
        >
        </el-pagination>
      </div>
      
    </div>
    
    <!--  设置会费-->
    <setMembership
        :show.sync="setMembershipFeeDialog"
        :data="manageGroup"
        @freshPage="initUserData(1,)"
        v-if="setMembershipFeeDialog"
    ></setMembership>
    
  </div>
</template>

<script>
import api from "@/fetch/api";
import mixin from "@/assets/js/mixin";
import moment from "moment";
import setMembership from "@/views/FinanceMenu/dialog/SetMembership.vue";

export default {
  mixins: [mixin],
  components:{
    setMembership
  },
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      myRight: Number(data.financePermission),
      noteDialog: false,
      chamberId: data.chamberId,
      adminId: data.id,
      tenantCode:data.tenantCode,
      userTotal: 0,
      pageSize: 10,
      currentPage: 1,
      membershipList:[],
      feeTotal:'',
      peopleTotal:'',
      active:0,
      open:true,
      
      // 搜索用
      companyName: "",
      realName: "",
      
      // 获取组别
      groups:[],
      page:1,
      stop:false,
      isLoading:false,
      maxPage:0,
      
      // 设置用
      expireDate:'',
      setMembershipFeeDialog:false,
      show:false,
      manageGroup:[],
      openDate:'',
      
    };
  },
  mounted() {
    this.getGroups(1);
  },
  updated() {
  },
  methods: {
    
    //            初始化用户列表
    initUserData(currentPage,groupId) {
      this.currentPage = currentPage;
      let param = {
        page:currentPage,
        currentAdmin:this.adminId,
        tenantCode: this.tenantCode,
        pageSize:this.pageSize,
        groupId:groupId
      }
      this.companyName
          ? (param.companyName = this.companyName)
          : delete param.companyName;
      this.realName
          ? (param.realName = this.realName)
          : delete param.realName;
      this.createTime
          ? (param.expireDate = moment(this.createTime))
          : delete param.expireDate;
      api.getMembershipList(param).then((res) => {
        this.membershipList = res.data.pageList;
        this.userTotal = res.data.total
      });
      api.getChamberMember({
        currentAdmin:this.adminId,
        groupId,
      }).then(res=>{
        this.feeTotal = res.data.totalPrice.toFixed(2)
        this.peopleTotal = res.data.totalMember
        this.expireDate = moment(res.data.expireDate).subtract(1,'day').format('YYYY-MM-DD')
        if(res.data.settingValidDate){
          console.log('res.data.settingValidDate ->',res.data.settingValidDate)
          this.openDate = res.data.settingValidDate.split(' ')[0]
          this.open = moment().diff(moment(this.openDate),'days') < 0
        }else if(res.data.settingValidDate===null){
          console.log('res.data.settingValidDate ->',res.data.settingValidDate)
          this.open = false
        }
        
      })
    },
  
    // 获取会员组别
    getGroups(page){
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
      };
      let param = {
        pageNum: page,
        pageSize: 8,
        language: languageObj[locale],
        chamberId: this.chamberId,
      }
      api.getGroupByChamberId(param).then(res=>{
        let obj = {}
        let list = []
        let source = res.data.data.records
        this.maxPage = res.data.data.pages
        source.forEach(record=>{
          obj = {}
          obj.groupId = record.groupId
          obj.groupName = record.groupName
          obj.node = record.node
          obj.groupNumber = record.groupNumber
          obj.id = record.id
          obj.price = record.price
          obj.priceType = record.priceType
          this.groups.push(obj)
        })
        this.active = this.groups[0].id
        this.groupId = this.groups[0].groupId
        this.initUserData(1,this.groupId)
      })
      //
    },
    
    //            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initUserData(currentPage,this.groupId);
    },
    
    // 切组
    chooseGroup(group){
      // console.log('group.id ->',group.id)
      this.active = group.id
      this.groupId = group.groupId
      this.initUserData(1,group.groupId)
    },
    
    // 无限滚动组
    loadMore(){
      // 如果不是最后一页，加载
      if(this.page<this.maxPage){
        this.load = true
        this.page += 1
        this.getGroups(this.page)
      }
      else if(this.page===this.maxPage){
        this.stop = true
      //   如果是最后一页
        /*let obj = {
          id:0,
          groupName: '到底了',
          end:true
        }
        this.groups.push(obj)
        this.$nextTick(()=>{
          const endElement = this.$refs[0][0]
          if(endElement){
            endElement.style.height = '50px'; // 设置高度
            endElement.style.backgroundColor = '#7F8387'
            endElement.style.color = 'white'
          }
        })*/
      }
    },
    
    //            搜索用户列表
    searchUserData() {
      // console.log('this.createTime ->',this.createTime)
      this.initUserData(1);
    },
    
    //   跳转详情页
    toDetail(data){
      this.$router.push({
        name:'FeeDetail',
        query:{
          id: data.userId,
          companyName:data.companyName,
          realName:data.realName
        }})
    },
  },
};
</script>
<style lang="scss" scoped>

.mainBody {
  width: 1600px;
  margin-top: 5px;
  display: flex;
  flex-flow: row nowrap;
}



.left-part{
  width: 16%;
  height: 700px;
  overflow-y: auto;
  //height: 200px;
  display: flex;
  flex-flow: column nowrap;
  margin-right: 15px;
  .per-group{
    height: 100px;
    border: 0.5px solid #EBEEF5;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 15px;
    line-height: 30px;
    justify-content: center;
    cursor: pointer;
  }
  li:not(:last-of-type) {
    border-bottom:none
  }
  /* 偶数行样式 */
  .per-group.even-row {
    background-color: #f2f2f2; /* 偶数行的背景颜色 */
  }
  
  /* 奇数行样式 */
  .per-group.odd-row {
    background-color: #ffffff; /* 奇数行的背景颜色 */
  }
  
  /* 激活（选中）行样式 */
  .per-group.active {
    background-color: #677D95;
    color: white;
  }
  
  .per-group:hover{
    background-color: #F5F7FA;
    color: black;
  }
  
}

.right-part{
  width: 84%;
}
.filterBarPay {
  width: 99%;
  margin: 5px auto 10px auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  
  .up{
    width: 100%;
    height: 75px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    justify-items: center;
    background-color: #677d95;
    border-radius: 5px;
    color: white;
    
    input {
      width: 200px;
      height: 30px;
      border: 1px solid white;
      margin: 8px 0;
      vertical-align: middle;
      float: left;
    }
    
    div label {
      margin: 8px 0 8px 10px;
      display: block;
      float: left;
      //line-height: 75px;
    }
    
    .el-button {
      width: 200px;
      height: 50px;
      line-height: 0.4;
      /*line-height: 50px;*/
      background-color: #677d95;
      color: white;
      
      &:hover {
        background-color: #9fbee6 !important;
      }
    }
  }
  
  .down{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    height: 50px;
    background-color: #677d95;
    color: white;
    border-radius: 5px;
    margin: 10px auto;
    
    .el-button {
      width: fit-content;
      height: 40px;
      line-height: 0.4;
      background-color: #677d95;
      color: white;
      
      &:hover {
        background-color: #9fbee6 !important;
      }
    }
  }
  
  
}

.el-table th {
  background-color: #677d95;
  color: white;
  opacity: 1;
}

.active {
  //background-color: #1C252C;
  background-color: #677d95 !important;
  color: white !important;
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

pre{
  white-space: pre-wrap;
  word-wrap: break-word;
}

.create-activity-dialog{
  width: 95%;
  padding: 20px 30px;
  border: 1px solid #666666;
  min-height: 50px;
  margin: auto;
  
  .el-form-item{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    
    ::v-deep .el-form-item__label{
      min-width: 180px !important;
      float: left;
      text-align:center;
    }
    
    .el-input{
      width: 500px;
    }
    
    .el-textarea{
      width: 500px;
    }
  }
  
  .per-pay{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    .el-select{
      width: 100px;
    }
    .pay-amount{
      width: 200px;
      margin: 0 20px;
      .el-input__inner{
        width: 200px!important;
      }
    }
  }
}

.group-form{
  width: 99%;
  margin: auto;
  display: flex;
  flex-flow: row nowrap;
}
/*.active{
  background-color: #677D95;
  color: white;
}*/
.infinite-list {
  height: 700px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  display: none;
}
.ellipsis-tooltip {
  white-space: nowrap;      /* 防止文本换行 */
  overflow: hidden;         /* 隐藏溢出的内容 */
  text-overflow: ellipsis;  /* 使用省略号代替溢出内容 */
  max-width: 100%;          /* 限制最大宽度 */
}
</style>

